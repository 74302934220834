import React from 'react'
import cx from 'classnames'
import { css, keyframes } from 'emotion'

const ANIMATION_DURATION = 5 // Time in seconds
const TRANSITION_DURATION = 0.2 // Amount of the the transition to the next slide takes (in seconds)
const END_SCALE = 1.1

// Animation code based on this codepen: https://codepen.io/ibanez182/pen/LZPgrY
export default function ImageSlider ({ className, images = [] }) {
  const slideAmount = images.length
  const totalAnimationDuration = ANIMATION_DURATION * slideAmount
  const durationPercentage = (ANIMATION_DURATION / totalAnimationDuration) * 100
  const transitionPercentage =
    (TRANSITION_DURATION / totalAnimationDuration / 2) * 100

  const slideBaseStyles = css`
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: ${totalAnimationDuration}s;
    opacity: 1;
    transform: scale(1);
    will-change: opacity, transform;
  `

  const slides = images.map((image, slideIndex) => {
    const i = slideIndex + 1
    const slideDurationStartPercentage = durationPercentage * slideIndex
    const slideDurationEndPercentage = durationPercentage * (slideIndex + 1)

    let t1 = slideDurationStartPercentage - transitionPercentage
    t1 = t1 < 0 ? 0 : t1

    let t2 = slideDurationStartPercentage + transitionPercentage
    t2 = t2 < 0 ? 0 : t2

    let t3 = slideDurationEndPercentage - transitionPercentage
    t3 = t3 > 100 ? 100 : t3

    let t4 = slideDurationEndPercentage + transitionPercentage
    t4 = t4 > 100 ? 100 : t4

    const t5 = 100 - transitionPercentage

    const scalePlus = (100 - t5) / t4
    const scalePlusBase = 1 + scalePlus / 100

    const kenBurnsAnimation = keyframes`
      ${t1}% { opacity: 1; transform: scale(1); }
      ${t2}% { opacity: 1; }
      ${t3}% { opacity: 1; }
      ${t4}% { opacity: 0; transform: scale(${END_SCALE}); }

      ${
  slideIndex + 1 !== slideAmount
    ? `100% { opacity: 0; transform: scale(1); }`
    : null
}

      ${
  slideIndex === 0
    ? `
        ${t5}% {
          opacity: 0;
          transform: scale(${scalePlusBase});
        }
        100% { opacity: 1; }
      `
    : null
}
    `

    return (
      <li
        key={image.id}
        className={cx(
          'h-full absolute top-0 left-0 w-full',
          slideBaseStyles,
          css`
            animation-name: ${kenBurnsAnimation};
            background-image: url(${image.resize.src});
            z-index: ${slideAmount - i};
          `
        )}
      />
    )
  })

  return (
    <ul className={cx(className, 'reset relative overflow-hidden z-10')}>
      {slides}
    </ul>
  )
}
