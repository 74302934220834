import React from 'react'
import { graphql } from 'gatsby'
import $ from '@gizt/selector'
import shuffle from 'lodash.shuffle'

import Layout from '../components/layout'
import PageTitle from '../components/page-title'
import ImageSlider from '../components/image-slider'
import MetaDescription from '../components/meta-description'

let cachedImages = null

export default function IndexPage ({ data: queryData }) {
  if (!cachedImages) {
    cachedImages = shuffle(
      $('images.edges[].node.childImageSharp.*', queryData)
    )
  }

  return (
    <Layout>
      <PageTitle className='hidden'>Home</PageTitle>
      <MetaDescription>
        Op zoek naar kleurrijke, spontane foto's van je dier of gezin? Boek dan
        hier je fotoshoot, tegen een betaalbare prijs. Laurence fotografeert
        voornamelijk in regio Antwerpen, Vlaanderen.
      </MetaDescription>
      <ImageSlider className='h-full' images={cachedImages} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    images: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { eq: "home" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            id
            resize(width: 1920) {
              src
            }
          }
        }
      }
    }
  }
`
